<template>
  <div
    class="microcart mw-100 fixed"
    data-testid="microcart"
  >
    <div class="m0 px40 mb10 microcart-header relative">
      <h2 v-if="productsInCart.length" class="pt30 cart-text weight-500 cl-black h3">
        {{ $t('Shopping cart') }}
      </h2>
      <p class="pointer weight-400 clear-cart m0 fs-small cl-black underline" v-if="productsInCart.length" @click="clearCart">
        {{ $t('Clear cart') }}
      </p>
      <h2 v-if="!productsInCart.length" class="pt30 pb30 weight-500 m0 cl-black h3">
        {{ $t('Your shopping cart is empty.') }}
      </h2>
      <button type="button" class="p0 brdr-none bg-cl-transparent close-microcart" @click="closeMicrocartExtend" data-testid="closeMicrocart">
        <i class="icon-close" />
      </button>
    </div>
    <div v-if="!productsInCart.length" class="mx30 pl10 cl-black" @click="closeMicrocartExtend">
      {{ $t("Don't hesitate and") }}
      <router-link class="cl-main weight-900 browse-link" :to="localizedRoute('/')">
        {{ $t('browse our catalog') }}
      </router-link>
      {{ $t('to find something beautiful for You!') }}
    </div>
    <div
      v-if="productsInCart.length && !isEdge"
      class="products visible-xs withoutInstant mt5"
    >
      <guide-notification class="visible-xs" />
      <component :is="SwipeActions" :products="productsInCart" />
    </div>
    <ul v-if="productsInCart.length" class="m0 products" :class="{ 'hidden-xs' : !isEdge }">
      <product v-for="product in productsInCart" :key="product.server_item_id || product.id" :microcart="true" :product="product" />
    </ul>
    <div class="footer-bottom env-padding-bottom">
      <div v-if="productsInCart.length" class="summary px40 cl-accent">
        <div class="row inner-container m0 end-xs visible-xs">
          <div v-if="OnlineOnly" class="col-xs-11 p0 visible-xs start-xs">
            <button
              class="p0 my10 weight-900 brdr-none fs-medium-small cl-black bg-cl-transparent discount-show"
              type="button"
              @click="addDiscountCoupon"
            >
              {{ $t('Add a discount code') }} <i class="arrow down" />
            </button>
          </div>
          <div class="flex middle-xs center-xs col-xs-1 visible-xs">
            <i @click="showMicrocartMobile = !showMicrocartMobile" :class="showMicrocartMobile ? 'icon-arrow_down_contour_48x48' : 'icon-arrow_up_contour_48x48'" class="h3 cl-gray" />
          </div>
        </div>
        <div class="row m0">
          <div v-if="OnlineOnly && !addCouponPressed" class="col-xs-12 p0 pb10 hidden-xs">
            <button
              class="p0 brdr-none fs-medium-small cl-black bg-cl-transparent discount-show underline"
              type="button"
              @click="addDiscountCoupon"
            >
              {{ $t('Add a discount code') }}
            </button>
          </div>
          <div v-if="OnlineOnly && addCouponPressed" class="col-xs-12 pt15 p0 pb10 coupon-wrapper middle-xs">
            <div class="coupon-input">
              <label class="h5 cl-black">{{ $t('Discount code') }} <span class="pl5 pointer cl-main" @click="clearCoupon"> {{ $t('Close') }} </span></label>
              <base-input type="text" id="couponinput" :autofocus="true" v-model.trim="couponCode" @keyup.enter="setCoupon" />
            </div>
            <button-outline color="dark" class="discount-btn" :disabled="!couponCode" @click.native="setCoupon">
              {{ $t('Add discount code') }}
            </button-outline>
          </div>
        </div>

        <div class="p0 show-order-details">
          <button
            class="p0 my10 weight-900 brdr-none fs-medium-small cl-black bg-cl-transparent"
            :class="{'mb0': showOrderDetails}"
            type="button"
            @click="toggleOrderDetails"
          >
            {{ $t('Order details') }} <i class="arrow" :class="{'top': showOrderDetails, 'down': !showOrderDetails}" />
          </button>
        </div>
        <div v-if="showOrderDetails">
          <div v-for="(segment, index) in totals" :key="index">
            <div v-if="(segment.code === 'subtotal' || segment.code === 'insurance_fee' || segment.code === 'discount') && segment.value !== null" class="detail-segment">
              <div class="p0 col-xs cl-black fs-medium-small">
                {{ $t(segment.title) }}:
              </div>
              <div v-if="segment.value !== null" class="p0 col-xs align-right cl-black fs-medium-small">
                {{ segment.value }}
              </div>
            </div>
          </div>
        </div>
        <div v-for="(segment, index) in totals" :key="index">
          <div v-if="segment.code === 'grand_total'" class="row py10 weight-700 middle-xs m0 total-info brdr-bottom-1 brdr-cl-secondary-gray" :class="{'brdr-top-1 brdr-cl-matterhorn': showMicrocartMobile}">
            <div class="col-xs h3 total-price-label cl-black p0">
              {{ $t(segment.title) }}:
            </div>
            <div class="col-xs align-right h3 total-price-value cl-black p0">
              {{ segment.value | totalWithoutShipping | price(storeView) }}
            </div>
          </div>
        </div>

        <p class="visible-xs m0 h6 cl-black">
          {{ $t('Select the shipping and delivery method in the next step') }}
        </p>
      </div>
      <div
        class="mx0 mb5 row py20 px40 middle-xs actions bg-cl-white between-xs"
        v-if="productsInCart.length && !isCheckoutMode"
      >
        <div class="col-xs-12 col-md-5 hidden-xs pl0">
          <button-outline class="w-100" @click="closeMicrocartExtend">
            {{ $t('Return to shopping') }}
          </button-outline>
        </div>
        <div class="col-xs-6 col-md-5">
          <button-full
            class="padding-mobile"
            :link="'checkout'"
            @click="closeMicrocartExtend"
          >
            {{ $t('Go to checkout') }}
          </button-full>
        </div>
        <div class="alternative-checkout col-xs-6 col-md-12 end-sm visible-xs" v-if="isInstantCheckoutRegistered && !isVirtualInCart && !isEnoughPoints">
          <div>
            <p class="label-1 cl-accent my5 hidden-xs">
              {{ $t('or') }}
            </p>
          </div>
          <no-ssr class="w-100">
            <instant-checkout />
          </no-ssr>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import i18n from '@vue-storefront/i18n'
import { isModuleRegistered, registerModule } from '@vue-storefront/core/lib/modules'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import VueOfflineMixin from 'vue-offline/mixin'
import onEscapePress from '@vue-storefront/core/mixins/onEscapePress'
import InstantCheckout from 'src/modules/vsf-instant-checkout/components/InstantCheckout.vue'

import GuideNotification from 'theme/components/core/blocks/Microcart/GuideNotification'
import BaseInput from 'theme/components/core/blocks/Form/BaseInput'
import ButtonFull from 'theme/components/theme/ButtonFull'
import ButtonOutline from 'theme/components/theme/ButtonOutline'
import Product from 'theme/components/core/blocks/Microcart/Product'
import NoSSR from 'vue-no-ssr'
import EditMode from './EditMode'
import { InstantCheckoutModule } from 'src/modules/instant-checkout'
import { BrowserDetected } from 'theme/components/core/BrowserDetected.ts'
import { round } from 'mathjs'

const SwipeActions = () => import(/* webpackPreload: true */ /* webpackChunkName: "SwipeActions" */ 'theme/components/core/blocks/Microcart/SwipeActions.vue')

export default {
  components: {
    Product,
    ButtonFull,
    ButtonOutline,
    BaseInput,
    InstantCheckout,
    GuideNotification,
    'no-ssr': NoSSR
  },
  mixins: [
    VueOfflineMixin,
    EditMode,
    onEscapePress,
    BrowserDetected
  ],
  data () {
    return {
      showMicrocartMobile: false,
      SwipeActions,
      addCouponPressed: !this.$device.isMobile,
      couponCode: '',
      componentLoaded: false,
      isInstantCheckoutRegistered: this.$store.getters['instant-checkout/getSupported'],
      showOrderDetails: false
    }
  },
  props: {
    isCheckoutMode: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  beforeCreate () {
    registerModule(InstantCheckoutModule)
  },
  mounted () {
    this.$nextTick(() => {
      this.componentLoaded = true
      if (this.isMicrocartOpen) {
        let body = document.getElementsByTagName('body')[0]
        body.classList.add('block-scroll')
      }
    })
  },
  destroyed () {
    let body = document.getElementsByTagName('body')[0]
    body.classList.remove('block-scroll')
  },
  computed: {
    ...mapState({
      isMicrocartOpen: state => state.ui.microcart
    }),
    ...mapGetters({
      productsInCart: 'cart/getCartItems',
      appliedCoupon: 'cart/getCoupon',
      totals: 'cart/getTotals',
      isOpen: 'cart/getIsMicroCartOpen',
      isVirtualInCart: 'cart/isVirtualInCart',
      getUserBalance: 'user/getBalance'
    }),
    storeView () {
      return currentStoreView()
    },
    isEnoughPoints () {
      return this.getUserBalance >= 1
    },
    totalWithoutShipping () {
      let grand = this.totals.find(x => x.code === 'grand_total')
      let shipping = this.totals.find(x => x.code === 'shipping')
      let discount = this.totals.find(x => x.code === 'discount')

      if ((grand && grand.code === 'grand_total') && (shipping && shipping.code === 'shipping')) {
        let priceFilter = function (price) { return round(price, 1) }
        let grandTotalWithoutShipping = grand.value - Math.abs(shipping.value * 1.23)
        if (discount) {
          grandTotalWithoutShipping = discount.value >= 0
            ? grandTotalWithoutShipping - discount.value : grandTotalWithoutShipping + discount.value
        }
        return priceFilter(grandTotalWithoutShipping)
      } else if ((grand && grand.code === 'grand_total') && !shipping) {
        return grand.value
      } else {
        return null
      }
    }
  },
  methods: {
    ...mapActions({
      applyCoupon: 'cart/applyCoupon'
    }),
    addDiscountCoupon () {
      this.addCouponPressed = true
    },
    clearCoupon () {
      this.$store.dispatch('cart/removeCoupon')
      this.addCouponPressed = false
    },
    toggleMicrocart () {
      this.$store.dispatch('ui/toggleMicrocart')
    },
    async setCoupon () {
      const couponApplied = await this.applyCoupon(this.couponCode)
      this.addCouponPressed = false
      this.couponCode = ''
      if (!couponApplied) {
        this.$store.dispatch('notification/spawnNotification', {
          type: 'warning',
          message: i18n.t("You've entered an incorrect coupon code. Please try again."),
          action1: { label: i18n.t('OK') }
        })
      }
    },
    closeMicrocartExtend () {
      this.toggleMicrocart()
      this.$store.commit('ui/setSidebar', false)
      this.addCouponPressed = false
    },
    onEscapePress () {
      this.$store.dispatch('ui/closeMicrocart')
    },
    clearCart () {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'warning',
        message: i18n.t('Are you sure you would like to remove all the items from the shopping cart?'),
        action1: { label: i18n.t('Cancel'), action: 'close' },
        action2: { label: i18n.t('OK'),
          action: async () => {
            // We just need to clear cart on frontend and backend.
            // but cart token can be reused
            await this.$store.dispatch('cart/clear', { disconnect: false })
          }
        },
        hasNoTimeout: true
      })
    },
    toggleOrderDetails () {
      this.showOrderDetails = !this.showOrderDetails
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "~theme/css/animations/transitions";
  @import '~theme/css/variables/colors';
  @import '~theme/css/helpers/functions/color';
  $color-black: color(black);
  $color-main: color(main);
  $matterhorn: color(matterhorn);
  .discount-show {
    i {
      margin-bottom: 3px;
      margin-left: 5px;
    }
  }
  @media (max-width: 767px) {
    .alternative-checkout {
        display: flex;
        flex-wrap: wrap;
        margin: 5px 0 5px 0px;
        padding-bottom: env(safe-area-inset-bottom, 20px);
        justify-content: space-between;
      }
     .alternative-checkout>div {
       width: 100%;
     }
  }
  .clear-cart {
    @media (max-width: 767px) {
    margin-left: 10px;
    padding-bottom: 10px;
    }
  }
  .padding-mobile {
    @media (max-width: 767px) {
    padding-left: 10px;
    padding-right: 10px;
    }
  }
  .cart-text {
    margin: 0;
    @media (max-width: 767px) {
    margin-left: 5px;
    }
  }
  .close-microcart {
    position: absolute;
    right: 25px;
    top: 53%;
    transform: translateY(-50%);
    @media (max-width: 767px) {
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    }
  }
  .microcart {
    top: 0;
    right: 0;
    z-index: 3;
    height: 100%;
    width: 800px;
    min-width: 320px;
    overflow-y: auto;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
    @media (max-width: 767px) {
      overflow: hidden;
      background-color: #F1F1F1;
    }
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
      width: 100%;
    }
  }
  .microcart-header {
    h2 {
      @media (max-width: 767px) {
        font-size: 24px;
        padding: 10px 5px;
      }
    }
    align-items: baseline;
    @media (max-width: 767px) {
      background-color: white;
      border-bottom-left-radius: 9px;
      border-bottom-right-radius: 9px;
      box-shadow: 0 1px 4px rgba(0,0,0,0.16), 0 1px 4px rgba(0,0,0,0.23);
      padding: 0 8px;
    }
  }
  .empty-microcart {
    padding: 15px 0;
  }
  .h3 {
    font-size: 32px;
  }
  .microcart>.products {
    overflow-y: scroll;
    scroll-behavior: smooth;
    padding: 0 40px;
    -webkit-overflow-scrolling: touch;
    max-height: calc(100vh - 225px);
    @media (max-width: 768px) {
      padding: 0;
    }
  }
  .footer-bottom {
    .total-info {
      @media (max-width: 767px) {
        border: 0;
        padding: 0;
      }
    }
    background-color: white;
    border-top: 1px solid #cccccc;
    padding-top: 10px;
    @media (min-width: 768px) {
      width: 800px;
    }
    position: fixed;
    bottom: 0;
    @media (max-width: 767px) {
      border-top-left-radius: 9px;
      border-top-right-radius: 9px;
      left: 0;
      right: 0;
    }
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
      width: 100%;
    }
  }
  .inner-container {
    padding-bottom: 10px;
    @media (max-width: 767px) {
      padding-bottom: 0;
    }
  }
  .custom-icon {
    border: 1px solid $color-main;
    padding: 11px 10px 6px 10px;
    border-radius: 50%;
  }
  .custom-icon-v2 {
    border: 1px solid $color-main;
    padding: 7px 10px 10px 10px;
    border-radius: 50%;
  }
  .arrow {
    border: solid black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
  }
  .top {
    transform: rotate(-135deg);
  }
  .down {
    transform: rotate(45deg);
  }
  .add-coupon {
    border: 1px solid;
    padding: 5px 12px;
  }
  .discount-btn {
    padding: 10px 20px;
    @media (max-width: 767px) {
      padding: 5px 25px;
      border: 2px solid $color-main;
    }
  }
  .btn-checkout {
    font-size: 16px !important;
    @media (max-width: 767px) {
      padding: 10px 30px;
    }
  }
  .btn-shopping {
    border: 3px solid black;
    padding: 13px 30px;
    text-align: center;
    box-sizing: border-box;
    span {
      font-weight: 900;
    }
  }
  @media (hover: hover) {
    .browse-link {
      &:hover:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 97%;
        height: 1px;
        background-color: $color-main;
      }
    }
  }

  .actions {
    @media (max-width: 767px) {
      padding: 3px;
    }
    .link {
      @media (max-width: 767px) {
        display: flex;
        justify-content: center;
        padding: 20px 70px;
        &.checkout {
          margin-top: 55px;
          padding: 0;
        }
      }
    }
  }
  .summary {
    @media (max-width: 767px) {
      padding:  0 10px;
      font-size: 12px;
    }
  }

  .total-price-label {
    @media (max-width: 767px) {
      font-size: 18px;
    }
  }

  .total-price-value {
    @media (max-width: 767px) {
      padding: 0;
      font-size: 24px;
    }
  }

  .delete-button {
    vertical-align: middle;
  }

  .coupon-wrapper {
    display: flex;
    align-items: flex-end;
    .button-outline {
      text-transform: inherit;
      width: 50%;
    }
    .coupon-input {
      margin-right: 20px;
      width: 100%;
    }
    @media (max-width: 767px) {
      display: flex;
      flex-direction: column;
      .coupon-input {
        margin-right: 0;
        width: 100%;
      }
      .coupon-input label {
        margin-bottom: 5px;
      }
      .button-outline {
        width: 100%;
        margin-top: 5px;
        margin-bottom: 5px;
      }
    }
  }
.alternative-checkout .apple-pay-butt {
    width: 48%;
    min-width: 0;
    padding: 0;
    font-size: 0px;
    border-radius: 30px;
    background-color: white;
    color: #000000;
    border: 1px solid #000000;
}
.alternative-checkout .pay-pal-butt {
    width: 48%;
    min-width: 0;
    padding: 0;
    border-radius: 30px;
    font-size: 0;
    background-color: #ffc439;
    color: #000000;
    border: 1px solid #eda317;
    padding: 0;
    background-image: none !important;
  }
   .alternative-checkout {
       display: flex;
       flex-wrap: wrap;
       // margin: 5px 0 5px 0px;
       justify-content: space-between;
     }
    .alternative-checkout>div {
      width: 100%;
      text-align: center;
    }
  .overlay {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    z-index: 0;
    height: 100%;
    background:rgba(0, 0, 0, 0.4);
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .4s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
  .detail-segment {
    border-top: 1px solid $matterhorn;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    gap: 10px;
  }
  .show-order-details {
    /* To hide border of first .detail-segment */
    position: relative;
    &::after {
      content: '';
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 100%;
      height: 3px;
      background-color: white;
    }
  }
  .show-order-details button {
    display: flex;
    align-items: center;
    gap: 8px;
    i.down {
      margin-bottom: 3px;
    }
  }
</style>
